import React from 'react';
import { Client } from 'boardgame.io/react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';

import game from '../game';
import Layout from './Layout';

const playerID = window.location.search.substring(1);

const config = {
  numPlayers: game.numPlayers,
  game,
  board: Layout,
  debug: process.env.NODE_ENV !== 'production',
};

if (playerID) {
  config.multiplayer = {
    server: window.location.origin,
  };
}

const GameClient = Client(config);

const App = () => {
  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <GameClient {...(playerID ? { playerID } : null)} />
    </DndProvider>
  );
};

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}

export default App;
